import * as React from "react";
import { BannerOption } from "../components/ActionButtons";
import BigHead from "../layouts/BigHead";

const LoginPage = () => {
  const title = "Log In";
  const description = "Log into your music glue account";

  return (
    <BigHead title={title} description={description} hideTitle>
      <section className="relative grow">
        <div className="md:grid md:grid-cols-3 gap-8 mb-12 pt-12 mx-auto">
          <BannerOption
            link="/customer-help"
            title="Customer Login"
            description="If you have bought something and have a query about your order"
          />
          <BannerOption
            link="https://user.musicglue.com/log-in"
            title="Seller Login"
            secondaryStyle
            description="If you use Music Glue to sell merch, music, tickets or fan clubs"
          />
          <BannerOption
            link="https://admin.musicglue.store/log-in"
            title="Lite Login"
            secondaryStyle
            description="If you are selling using the newer 'Lite' version of Music Glue"
          />
        </div>
      </section>
    </BigHead>
  );
};

export default LoginPage;
